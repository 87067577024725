import React, { useState } from 'react';
import { Box, Dialog, Grid, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Height } from '@mui/icons-material';

const ModalTitle = styled(Typography)(({ theme }) => ({
//   fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '17px',
  lineHeight: '20px',
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: 'break-word',
  // overflow: 'break-word',
  nowrap: false,
  cursor: 'pointer',
  "&:hover": {
    color: "#fff",
    borderBottom: '1px solid white'
  },
  transition: 'all 0.25s ease-in-out',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
}));

const OpenIcon = styled(LaunchIcon)(({ theme }) => ({
  transition: 'all 0.45s ease-in-out',
  // fill: 'white'
}));

const FooterSection = styled(Box)(({ theme }) => ({
    width: '100%',
    paddingLeft: '10px',
    bottom: '0px',
    height: '60px',
    transform: 'translate3d(0px, 0px, 0px)',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.5s ease-in-out',
    background: "rgba(255,255,255,0.1)",
    // padding: '2px',
    maxWidth: '1200px',
    gap: '10px',
    marginBottom: '0px',
    textAlign: 'center',
    
    justifyContent: 'left',
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
      height: '80px',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      height: '60px',
    },
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        height: '60px',
      },
  }));

interface Props {
    data: any
}

const Navigation: React.FC<Props> = ({ data }) => {
    const [isMouseOnTarget, setIsMouseOnTarget] = useState(false);
    const navigate = useNavigate();

    const onMouseEnter = async () => {
        setIsMouseOnTarget(true)
    };

    const getTranslate = (val: string) => {
        if( val.length === 0 ){
            return "Главная"
        }
        if( val === "company" ){
            return "Компания"
        }
        if( val === "products" ){
            return "Продукция"
        }
        if( val === "podves" ){
            return "Целевые нагрузки"
        }
        if( val === "gallery" ){
            return "Фтографии"
        }
        if( val === "aero" ){
            return "Аэростаты"
        }
        if( val === "mpak" ){
            return "МПАК"
        }
        if( val === "possibilitys" ){
            return "Возможности"
        }
        if( val === "contacts" ){
            return "Контакты"
        }
        if( val === "licenses" ){
            return "Лицензии и сертификаты"
        }
        if( val === "possibility_1" ){
            return "Разработка электроники"
        }
        if( val === "possibility_2" ){
            return "Разработка ПО"
        }
        if( val === "p3" ){
            return "Наши возможности 3"
        }
        if( val === "p4" ){
            return "Наши возможности 4"
        }
        if( val === "pgp06dn" ){
            return "ПГП 06-ДН"
        }
        if( val === "pgp06nd" ){
            return "ПГП 06-НД"
        }
        if( val === "vertical" ){
            return "Вертикаль"
        }
        if( val === "mobilePoints" ){
            return "Мобильные пункты"
        }
        if( val === "mpu" ){
            return "МПУ"
        }
        if( val === "nabl" ){
            return "Системы наблюдения"
        }
        if( val === "nabludatel" ){
            return "Наблюдатель"
        }
        if( val === "nabludatel_N" ){
            return "Наблюдатель-Н"
        }
        if( val === "vzglyad" ){
            return "Взгляд"
        }
        if( val === "vzglyadN" ){
            return "Взгляд-Н"
        }
        if( val === "snegir" ){
            return "Снегирь"
        }
        if ( val === "career" ){
            return "Вакансии"
        }
        if( val === "career1" ){
            return "Инженер-электронщик / Инженер-регулировщик"
        }
        if( val === "career2" ){
            return "Ведущий программист встраиваемых систем Linux"
        }
        if( val === "career3" ){
            return "Слесарь-сборщик"
        }
        if( val === "career4" ){
            return "Слесарь-сборщик/ Слесарь-монтажник"
        }
        if( val === "career5" ){
            return "Монтажник РЭА"
        }
        if( val === "career6" ){
            return "Специалист отдела технического контроля/Контролёр ОТК/менеджер по качеству"
        }

        return val
    };

    const onGoLink = async (link: string, index: number) => {
        console.log(link)
        if( link === "" ){
            navigate("/overview")
            window.scrollTo({top: 0, left: 0})
            return
        } 
        let navigateUrl = ""

        for(let i = 0; i < data.length; i+=1 ){
            if( data[i].length > 0 ){
                navigateUrl += `/${data[i]}`;
            }
            if( data[i] === link )
                break
        }
        console.log(navigateUrl)
        navigate(`${navigateUrl}`)
        window.scrollTo({top: 0, left: 0})
    };

  return (
    <FooterSection>
        <Grid sx={{padding: '5px'}} container spacing={1}>
        <>{data && data
            .map((value: any, index: number) => 
                <Grid item >
                <Box gap="5px" display='flex' alignItems='center' >
                    <ModalTitle onClick={() => {
                        onGoLink(value, index)
                    }}> {getTranslate(value) ? getTranslate(value) : ""}</ModalTitle>
                    {index !== data.length -1 && 
                        <ArrowBackIosNewIcon sx={{transform: 'rotate(180deg)', height: '15px', width: '15px'}}/>
                    }
                </Box> 
                </Grid>
        )}</> 
        </Grid>

    </FooterSection>
  );
};

export default Navigation;
